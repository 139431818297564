.header {
  width: calc(100% - 140px);
  height: 74px;
  margin: 0 auto 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header__logo {
  width: 38px;
  height: 38px;
  background-image: url("../../images/header_logo.svg");
  border: none;
  background-position: center;
  background-color: #ffffff;
  padding: 0;
}

.header__logo:hover {
  opacity: 0.8;
  cursor: pointer;
}

.header__signup {
  margin-right: 20px;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  color: #000000;
}

.header__signup:hover {
  opacity: 0.7;
  cursor: pointer;
}

.header__button-in {
  width: 76px;
  height: 32px;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background-color: #3456F3;
  border: none;
  border-radius: 3px;
  padding: 0;
  color:#ffffff;
}

.header__button-in:hover {
  opacity: 0.8;
  cursor: pointer;
}

.header__menu-open {
  width: 44px;
  height: 44px;
  background-image: url("../../images/header-menu-open.svg");
  display: none;
  border: none;
  background-color: #ffffff;
  background-position: center;
  padding: 0;
  position: absolute;
  right: 0;
}

.header__menu-open:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media screen and (max-width: 880px) {
  .header {
    width: calc(100% - 60px);
  }

  .header__menu-open {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .header {
    width: calc(100% - 28px);
    margin-bottom: 32px;
  }

  .header__signup {
    font-size: 10px;
    margin-right: 14px;
  }

  .header__button-in {
    font-size: 10px;
  }
}
