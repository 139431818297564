.register {
  width: 396px;
  min-height: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 70px 0;
}

.register__link {
  align-self: flex-start;
  margin-bottom: 40px;
}

.register__link:hover {
  opacity: 0.8;
  cursor: pointer;
}

.register__logo {
  width: 38px;
  height: 38px;
  background-image: url("../../images/header_logo.svg");
  border: none;
  background-size: contain;
  background-color: #ffffff;
  padding: 0;
}

.register__title {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 40px;
}

.register__data-lable {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #a0a0a0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 10px;
  align-self: flex-start;
}

.register__data {
  width: 396px;
  height: 46px;
  background-color: #f9f9f9;
  border: none;
  border-radius: 8px;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding: 0;
  margin-bottom: 10px;
}

.register__data_active {
  color: #ee3465;
}

.register__error {
  height: 12px;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ee3465;
  margin-bottom: 5px;
  align-self: flex-start;
}

.register__error:last-of-type {
  margin-bottom: 69px;
}

.register__error-submit {
  height: 12px;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #ee3465;
  align-self: center;
  margin-bottom: 20px;
}

.register__finish {
  width: 396px;
  height: 45px;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #c2c2c2;
  background-color: #f8f8f8;
  margin-bottom: 16px;
  border: none;
  border-radius: 3px;
}

.register__finish_active {
  background-color: #ff6838;
  color: #ffffff;
}

.register__finish:hover {
  opacity: 0.8;
  cursor: pointer;
}

.register__login-text {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-block-start: 0;
  margin-block-end: 0;
  color: #a0a0a0;
}

.register__login-text_signin {
  color: #3456f3;
  margin-left: 6px;
}

.register__login-text_signin:hover {
  opacity: 0.7;
  cursor: pointer;
}

.register__login {
  display: flex;
}

@media screen and (max-width: 770px) {
  .register {
    height: 560px;
    padding-top: 232px;
    padding-bottom: 232px;
  }
}

@media screen and (max-width: 420px) {
  .register {
    width: calc(100% - 60px);
    height: 694px;
    padding-top: 56px;
    padding-bottom: 30px;
  }

  .register__data {
    width: 100%;
  }

  .register__finish {
    width: 100%;
    font-size: 12px;
    line-height: 15px;
  }

  .register__link {
    align-self: center;
  }

  .register__logo {
    margin-bottom: 50px;
  }

  .register__title {
    margin-bottom: 80px;
  }

  .register__error {
    margin-bottom: 157px;
  }

  .register__login-text_signin {
    margin-left: 7px;
  }

  .register__login-text {
    font-size: 12px;
    line-height: 15px;
  }
}
