.promo {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 80px);
  height: 493px;
  margin: 0 auto;
  border-radius: 10px;
}

.promo__title {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  width: 730px;
  text-align: center;

  margin-block-start: 0;
  margin-block-end: 0;
  margin: 160px auto 151px;
}

@media screen and (max-width: 820px) {
  .promo {
    width: calc(100% - 40px);
    height: 834px;
  }

  .promo__title {
    font-size: 40px;
    line-height: 52px;
    max-width: 680px;
    margin: 350px auto 314px;
  }
}

@media screen and (max-width: 805px) {
  .promo__title {
    font-size: 29px;
    line-height: 37px;
    width: 292px;
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 500px) {
  .promo {
    width: calc(100% - 28px);
    height: 586px;
  }

  .promo__title {
    margin: 200px auto 180px;
  }

  
}
