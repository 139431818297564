.error {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error__code {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 140px;
  line-height: 169px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 246px;
  margin-bottom: 5px;
}

.error__text {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 184px;
}

.error__link {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3456F3;
  margin-bottom: 60px;
  text-decoration: none;
}

.error__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 770px) {
  .error__code {
    margin-top: 408px;
  }

  .error__link {
    margin-bottom: 222px;
  }
}

@media screen and (max-width: 350px) {
  .error__code {
    margin-top: 329px;
    font-size: 80px;
    line-height: 97px;
    margin-bottom: 10px;
  }

  .error__link {
    margin-bottom: 30px;
    font-size: 12px;
    line-height: 15px;
  }

  .error__text {
    margin-bottom: 286px;
    font-size: 12px;
    line-height: 15px;
  }
}
