.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  margin: 0 auto;
}

.footer__title {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #a0a0a0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 20px;
  margin-top: 79px;
}

.footer__line {
  border: none;
  background-color: #e8e8e8;
  height: 1px;
  width: calc(100% - 140px);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 20px;
}

.footer__menu-block {
  width: calc(100% - 140px);
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.footer__year {
  margin-block-start: 0;
  margin-block-end: 0;
}

.footer__link {
  text-decoration: none;
  color: #000000;
  margin-right: 20px;
}

.footer__link:hover {
  opacity: 0.7;
  cursor: pointer;
}


.footer__link:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 880px) {
  .footer {
    background-color: #ffffff;
  }

  .footer__line {
    width: calc(100% - 60px);
  }

  .footer__menu-block {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 500px) {
  .footer__line {
    width: calc(100% - 20px);
    margin-bottom: 30px;
  }

  .footer__menu-block {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 15px;
  }

  .footer__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer__link {
    margin-bottom: 12px;
    margin-right: 0;
  }

  .footer__year {
    margin-top: 18px;
  }

  .footer__title {
    font-size: 12px;
    line-height: 15px;
  }
}
