.techs {
  margin: 0 auto;
  width: 100%;
  height: 625px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
}

.techs__title {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  align-self: flex-start;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 100px;
  margin-bottom: 23px;
  margin-left: 70px;
}

.techs__line {
  border: none;
  background-color: #000000;
  height: 1px;
  width: calc(100% - 140px);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 90px;
}

.techs__subtitle {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 26px;
}

.techs__text {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 100px;
  width: 460px;
  text-align: center;
}

.techs__list {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.techs__tech {
  width: 90px;
  height: 60px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: #e8e8e8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.techs__button:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 775px) {
  .techs {
    margin-top: 90px;
    height: 567px;
  }

  .techs__tech {
    width: 84px;
    height: 57px;
    font-size: 12px;
    line-height: 15px;
  }

  .techs__title {
    margin-top: 90px;
  }

  .techs__line {
    margin-bottom: 80px;
  }

  .techs__subtitle {
    margin-bottom: 22px;
  }

  .techs__text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 83px;
  }
}

@media screen and (max-width: 740px) {
  .techs__list {
    flex-wrap: wrap;
  }

  .techs {
    height: 692px;
  }

  .techs__tech {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  .techs__title {
    font-size: 18px;
    line-height: 22px;
    margin-left: 18px;
  }

  .techs__line {
    width: calc(100% - 36px);
  }

  .techs__subtitle {
    font-size: 30px;
    line-height: 36px;
  }

  .techs__text {
    font-size: 11px;
    line-height: 16px;
    width: calc(100% - 28px);
    margin-bottom: 50px;
  }

  .techs__list {
    width: 200px;
    margin-bottom: 60px;
    justify-content: flex-start;
  }
}
