.profile {
  width: 100%;
  height: 482px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 74px;
  padding-bottom: 70px;
}

.profile__title {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 123px;
}

.profile__data {
  width: 410px;
  height: 56px;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-bottom: 245px;
}

.profile__name {
  height: 30px;
  padding: 0 0 12px;
  border: none;
  width: 410px;
  text-align: end;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.profile__email {
  height: 30px;
  padding: 13px 0 0;
  border: none;
  width: 410px;
  text-align: end;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.profile__line {
  border: none;
  background-color: #e8e8e8;
  height: 1px;
  width: 408px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.profile__lable-name {
  position: absolute;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  top: 3px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.profile__lable-mail {
  position: absolute;
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  top: 42px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.profile__edit {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  border: none;
  background-color: #ffffff;
  opacity: 0.5;
  padding: 0;
  margin-bottom: 16px;
}

.profile__edit_active {
  opacity: 1;
}

.profile__edit:hover {
  opacity: 0.7;
  cursor: pointer;
}

.profile__exit {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #ee3465;
  text-decoration: none;
}

.profile__exit:hover {
  opacity: 0.7;
  cursor: pointer;
}

.profile__manage-block {
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 770px) {
  .profile {
    height: 445px;
    padding-top: 236px;
    padding-bottom: 269px;
  }

  .profile__title {
    margin-bottom: 96px;
  }

  .profile__data {
    margin-bottom: 210px;
  }
}

@media screen and (max-width: 500px) {
  .profile {
    height: 596px;
    padding-top: 70px;
    padding-bottom: 40px;
  }

  .profile__title {
    margin-bottom: 80px;
  }

  .profile__data {
    margin-bottom: 379px;
    width: calc(100% - 60px);
  }

  .profile__name {
    width: 100%;
    padding-bottom: 10px;
  }

  .profile__email {
    width: 100%;
    padding-top: 10px;
  }

  .profile__line {
    width: 100%;
  }
}

.profile__success-message {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
