.aboutme {
  margin: 110px auto 0;
  width: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.aboutme__title {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  align-self: flex-start;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 23px;
}

.aboutme__line {
  border: none;
  background-color: #000000;
  height: 1px;
  width: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 66px;
}

.aboutme__name {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  align-self: flex-start;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 18px;
}

.aboutme__profession {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  align-self: flex-start;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 26px;
}

.aboutme__description {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-self: flex-start;
  width: 600px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 50px;
}

.aboutme__menu {
  align-self: flex-start;
}

.aboutme__link {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: #000000;
  margin-right: 20px;
}

.aboutme__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

.aboutme__link:last-of-type {
  margin-right: 0;
}

.aboutme__myfoto {
  position: absolute;
  top: 120px;
  right: 0;
  border-radius: 10px;
}

@media screen and (max-width: 950px) {
  .aboutme__description {
    width: 364px;
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 770px) {
  .aboutme {
    margin-top: 90px;
    width: calc(100% - 100px);
  }

  .aboutme__name {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 16px;
  }

  .aboutme__profession {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 675px) {
  .aboutme__description {
    width: 250px;
  }
}

@media screen and (max-width: 540px) {
  .aboutme {
    margin-top: 70px;
    margin-bottom: 70px;
    width: calc(100% - 28px);
  }

  .aboutme__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 28px;
  }

  .aboutme__myfoto {
    position: static;
    margin-bottom: 40px;
  }

  .aboutme__description {
    width: 100%;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 40px;
  }

  .aboutme__name {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .aboutme__profession {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .aboutme__link {
    margin-right: 26px;
  }
}